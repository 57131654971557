import request from '@/utils/request'

const userApi = {
  userInfo: 'user/info',
  image: 'upload/image',
  editUserInfo: 'User/editUserInfo',
  getUserAuthentication: 'user/getUserAuthentication',
  userAuthentication: 'user/userAuthentication',
  wrongQuestionList: 'exam.exam/wrongQuestionList',
  deleteWrongQuestion: 'exam/deleteWrongQuestion',
  collectQuestionsList: 'course.Course/getCollextionList',
  collectList: 'course.collect/list',
  getJobCreditList: 'userJobPositions/getJobCreditList',
  JobCreditLogs: 'userJobPositions/JobCreditLogs',
  resetPwd: 'User/resetPwd',
  courseLearnRecords: 'course.course/courseLearnRecords',
  monthLearnStatistics: 'course.course/monthLearnStatistics',
  learnStatistics: 'course.course/learnStatistics',
  bindMobile: 'user/bindMobile',
  CollectionActivate: 'course.Course/CollectionActivate',
  CollectionList: 'course.Course/CollectionList',
  CertList: 'User/CertList',
  Group: 'User/group',
  updateGroup: 'User/updateGroup',
  mergeUser: 'user/mergeUser',
  mergeUserByMobile: 'user/mergeUserByMobile'
}

export function mergeUserByMobile (parameter) {
  return request({
    url: userApi.mergeUserByMobile,
    method: 'post',
    data: parameter
  })
}

//  获取个人的课程包列表
export function mergeUser (parameter) {
  return request({
    url: userApi.mergeUser,
    method: 'post',
    data: parameter
  })
}

//  获取个人的课程包列表
export function updateGroup (parameter) {
  return request({
    url: userApi.updateGroup,
    method: 'post',
    data: parameter
  })
}

//  证书列表
export function Group (parameter) {
  return request({
    url: userApi.Group,
    method: 'get',
    params: parameter
  })
}

//  证书列表
export function CertList (parameter) {
  return request({
    url: userApi.CertList,
    method: 'get',
    params: parameter
  })
}

//  获取个人的课程包列表
export function CollectionList (parameter) {
  return request({
    url: userApi.CollectionList,
    method: 'post',
    data: parameter
  })
}

export function CollectionActivate (parameter) {
  return request({
    url: userApi.CollectionActivate,
    method: 'post',
    data: parameter
  })
}

export function bindMobile (parameter) {
  return request({
    url: userApi.bindMobile,
    method: 'post',
    data: parameter
  })
}

// 饼图统计
export function learnStatistics (parameter) {
  return request({
    url: userApi.learnStatistics,
    method: 'get',
    params: parameter
  })
}

// 学习月统计
export function monthLearnStatistics (parameter) {
  return request({
    url: userApi.monthLearnStatistics,
    method: 'get',
    params: parameter
  })
}

export function courseLearnRecords (parameter) {
  return request({
    url: userApi.courseLearnRecords,
    method: 'get',
    params: parameter
  })
}

export function resetPwd (parameter) {
  return request({
    url: userApi.resetPwd,
    method: 'post',
    data: parameter
  })
}
export function JobCreditLogs (parameter) {
  return request({
    url: userApi.JobCreditLogs,
    method: 'get',
    params: parameter
  })
}

export function getJobCreditList (parameter) {
  return request({
    url: userApi.getJobCreditList,
    method: 'get',
    params: parameter
  })
}

export function collectList (parameter) {
  return request({
    url: userApi.collectList,
    method: 'get',
    params: parameter
  })
}
// 获取个人的课程列表
export function collectQuestionsList (parameter) {
  return request({
    url: userApi.collectQuestionsList,
    method: 'get',
    params: parameter
  })
}

export function deleteWrongQuestion (parameter) {
  return request({
    url: userApi.deleteWrongQuestion,
    method: 'post',
    data: parameter
  })
}

export function wrongQuestionList (parameter) {
  return request({
    url: userApi.wrongQuestionList,
    method: 'get',
    params: parameter
  })
}

export function userAuthentication (parameter) {
  return request({
    url: userApi.userAuthentication,
    method: 'post',
    data: parameter
  })
}

export function getUserAuthentication (parameter) {
  return request({
    url: userApi.getUserAuthentication,
    method: 'get',
    params: parameter
  })
}

export function editUserInfo (parameter) {
  return request({
    url: userApi.editUserInfo,
    method: 'post',
    data: parameter
  })
}

export function image (formData) {
  return request({
    url: userApi.image,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function userInfo (parameter) {
  return request({
    url: userApi.userInfo,
    method: 'get',
    params: parameter
  })
}
